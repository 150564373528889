var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"Group_Themes","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',{staticClass:"v-card--material pa-3"},[_c('card-heading',{attrs:{"color":'secondary',"title":'Group themes'}},[(
							_vm._usrFlagsSome({
								key: 'bonus.group_themes.detail',
								val: _vm.permissions.CREATE,
							})
						)?_c('v-btn',{attrs:{"color":"primary","elevation":"1","fab":"","small":""},on:{"click":_vm.onAddClick}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-data-table',{ref:"table",staticClass:"elevation-1",attrs:{"headers":_vm.filteredHeaders,"items":_vm.list,"options":_vm.options,"server-items-length":_vm.total_records,"item-class":_vm.itemClass,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function({ pagination }){return [_c('v-data-footer',_vm._b({staticClass:"anton",attrs:{"options":_vm.options,"pagination":pagination},on:{"update:options":function($event){_vm.options=$event}}},'v-data-footer',_vm.footerProps,false))]}},{key:`item.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" "),(
								item.deleted &&
								_vm._usrFlagsSome({
									key: 'bonus.group_themes.list',
									val: _vm.permissions.DELETE,
								})
							)?_c('v-chip',{staticClass:"ml-3",attrs:{"small":"","color":"error"}},[_vm._v(" Deleted ")]):_vm._e()]}},{key:`item.deleted`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("Date")(item.deleted))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"color":"primary darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
										_vm._usrFlagsSome({
											key: 'bonus.group_themes.detail',
											val: _vm.permissions.READ,
										}) && item.deleted === null
									)?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"size":"22","color":"primary"},on:{"click":function($event){return _vm.onEditItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" visibility ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Detail")])]),_c('v-tooltip',{attrs:{"color":"primary darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
										_vm._usrFlagsSome({
											key: 'bonus.group_themes.detail',
											val: _vm.permissions.READ,
										}) && item.deleted === null
									)?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"size":"22","color":"primary"},on:{"click":function($event){return _vm.exportJson(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-arrow-down-bold ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Export")])]),_c('v-tooltip',{key:`${item.id}-${item.deleted}-deleteBtn`,attrs:{"color":"primary darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
										item.deleted == null &&
										_vm._usrFlagsSome({
											key: 'bonus.group_themes.detail',
											val: _vm.permissions.DELETE,
										})
									)?_c('ConfirmDialog2',{attrs:{"persistent":"","confirmBtnText":'Delete',"cancelBtnText":'Back',"confirmBtnColor":'error',"titleClass":'error',"cancelBtnColor":'kajot-text',"shouldShow":true},on:{"confirm":function($event){return _vm.onDeleteItem(item.name)}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" This will deactivate this Theme. "),_c('br'),_c('b',[_vm._v("Do you want to proceed?")])]},proxy:true},{key:"default",fn:function({ showConfirm }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"size":"22","color":"primary"},on:{"click":showConfirm}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)}):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete")])]),_c('v-tooltip',{key:`${item.id}-${item.deleted}-restoreBtn`,attrs:{"color":"primary darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
										item.deleted != null &&
										_vm._usrFlagsSome({
											key: 'bonus.group_themes.detail',
											val: _vm.permissions.DELETE,
										})
									)?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"size":"22","color":"primary"},on:{"click":function($event){return _vm.onRestoreItem(item.name)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-restore ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Restore")])])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.addEditDialog),callback:function ($$v) {_vm.addEditDialog=$$v},expression:"addEditDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary"},[_c('span',{staticClass:"text-h5"},[_vm._v("Add New Theme")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"themeForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Theme Name","rules":[_vm.rules.required, _vm.rules.no_spaces]},model:{value:(_vm.themeName),callback:function ($$v) {_vm.themeName=$$v},expression:"themeName"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-space-between"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onAddEditClose}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.valid},on:{"click":_vm.onAddEditSave}},[_vm._v(" Save ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }